<template>
  <div
    :class="{ 'shrinkreport-content': drawer && !isMobile }"
    :style="{ 'margin-top': isMobile ? '65px' : '100px' }"
  >
    <v-container>
      <v-row align="center" justify="center">
        <HomeItem
          v-if="hasAccessToProjects"
          v-cloak 

          :url="'/projects'"
          :title="$t('projects.projects')"
        >
          <ProjectIcon style="margin-top:15px;" :width="iconWidth" :height="iconHeight" :active="true" />
        </HomeItem>
        
        <HomeItem
          v-if="hasAccessToDocumentRequests"
          v-cloak

          :url="'/documentRequests'"
          :title="$t('documentRequests.documentRequests')"
        >
          <DocumentRequestIcon style="margin-top:15px;" :width="iconWidth" :height="iconHeight" :active="true" />
        </HomeItem>

        <HomeItem
          v-if="hasAccessToFieldReports"
          :url="'/reports'"
          :title="$t('documentRequests.dr_fieldreports')"
        >
          <FieldReportIcon style="margin-top:15px;" :width="iconWidth" :height="iconHeight" :active="true" />
        </HomeItem>
  
        <HomeItem
          v-if="canManageDealers"
          :url="'/users'"
          :title="$t('home.user')"
        >
          <UsersIcon style="margin-top:15px;" :width="iconWidth" :height="iconHeight" :active="true" />
        </HomeItem>

        <HomeItem
          v-if="canManageMachineCalibrations"
          v-cloak

          :url="'/engineeringDepartments'"
          :title="$t('general.engineering_department')"
        >
          <EngineeringDepartmentsIcon style="margin-top:15px;"  :width="iconWidth" :height="iconHeight" :active="true" />
        </HomeItem>

        <HomeItem
          v-cloak

          :url="'/videos'"
          :title="$t('general.videos')"
        >
          <VideoIcon style="margin-top:15px;" :width="iconWidth" :height="iconHeight" :active="true" />
        </HomeItem>

        <HomeItem
          v-cloak

          :url="'/news'"
          :title="$t('general.news')"
        >
          <NewsIcon style="margin-top:15px;" :width="iconWidth" :height="iconHeight" :active="true" />
        </HomeItem>

        <HomeItem
          v-if="canManageDealers"
          v-cloak

          :url="'/dealers'"
          :title="$t('home.dealer')"
        >
          <DealersIcon style="margin-top:15px;" :width="iconWidth" :height="iconHeight" :active="true" />
        </HomeItem>

        <HomeItem
          v-if="hasAccessToCustomers"
          v-cloak

          :url="'/customers'"
          :title="$t('home.customer')"
        >
          <CustomersIcon style="margin-top:15px;" :width="iconWidth" :height="iconHeight" :active="true" />
        </HomeItem>

        <HomeItem
          v-if="hasAccessToMap"
          v-cloak

          :url="'/map'"
          :title="$t('home.map')"
        >
          <MapIcon style="margin-top:15px;" :width="iconWidth" :height="iconHeight" :active="true" />
        </HomeItem>

        <!-- <HomeItem
          v-if="hasAccessToBetaTestSection"
          v-cloak

          :url="'/marketing'"
          :title="$t('home.marketing')"
        >
          <MarketingIcon style="margin-top:15px;" :width="iconWidth" :height="iconHeight" :active="true" />
        </HomeItem> -->

        <HomeItem
          v-if="canManageParameters"
          v-cloak

          :url="'/settings'"
          :title="$t('general.settings')"
        >
          <SettingsIcon style="margin-top:15px;" :width="iconWidth" :height="iconHeight" :active="true" />
        </HomeItem>

      </v-row>
      
    </v-container>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import HomeItem from '@/components/menus/HomeItem'

import FieldReportIcon from '@/components/Icons/FieldReportIcon.vue'
import UsersIcon from '@/components/Icons/UsersIcon'
import DealersIcon from '@/components/Icons/DealersIcon'
import CustomersIcon from '@/components/Icons/CustomersIcon'
import SettingsIcon from '@/components/Icons/SettingsIcon'
import EngineeringDepartmentsIcon from '@/components/Icons/EngineeringDepartmentsIcon'
//import MarketingIcon from '@/components/Icons/MarketingIcon'
import VideoIcon from '@/components/Icons/VideoIcon'
import NewsIcon from '@/components/Icons/NewsIcon'
import MapIcon from '@/components/Icons/MapIcon'
import ProjectIcon from '@/components/Icons/ProjectIcon'
import DocumentRequestIcon from '@/components/Icons/DocumentRequestIcon'

export default {
  name: "Home",
  components: {
    HomeItem,
    FieldReportIcon,
    UsersIcon,
    DealersIcon,
    CustomersIcon,
    SettingsIcon,
    EngineeringDepartmentsIcon,
    //MarketingIcon,
    VideoIcon,
    NewsIcon,
    MapIcon,
    ProjectIcon,
    DocumentRequestIcon
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
    }),
    ...mapGetters({
      canManageDealers: 'canManageDealers',
      canManageParameters: 'canManageParameters',
      canManageMachineCalibrations: 'canManageMachineCalibrations',
      hasAccessToMap: 'hasAccessToMap',
      hasAccessToCustomers: 'hasAccessToCustomers',
      hasAccessToBetaTestSection: 'hasAccessToBetaTestSection',
      hasAccessToDocumentRequests: 'hasAccessToDocumentRequests',
      hasAccessToFieldReports: 'hasAccessToFieldReports',
      hasAccessToProjects: 'hasAccessToProjects'
    }),
    iconWidth: () => '100px',
    iconHeight: () => '100px',
  },
  methods: {
  },
  data() {
    return {
      locale: this.$i18n.locale,
    };
  },
  mounted() {
  },
};
</script>
<style scoped>

.v-badge--tile .v-badge__badge {
  border-radius: 3px;
}

@media (min-width: 1904px) {
  .container {
    max-width: 1425px;
  }
}

</style>
