const REQUEST_TYPES = {
    FIELD_REPORT: 0,
    RECOMMENDATION_REQUEST: 1,
    PERMIT_REQUEST: 2,
    TEST_INSTALLATION_FIELD: 3
}

const REQUEST_TYPES_TEXT = {
  [REQUEST_TYPES.FIELD_REPORT]: 'documentRequests.dr_fieldreport',
  [REQUEST_TYPES.RECOMMENDATION_REQUEST]: 'documentRequests.dr_recommendation',
  [REQUEST_TYPES.PERMIT_REQUEST]: 'documentRequests.dr_permit',
  [REQUEST_TYPES.TEST_INSTALLATION_FIELD]: 'documentRequests.dr_testinstallationfieldnote'
}

const DOCUMENT_REQUEST_TYPES = {
    ATTESTATION_REQUEST: 0,
    STAMPED_FIELD_REPORT: 1,
    BUILDING_DEPART_INSPECTION_FORM: 2,
    T_PRO: 3,
    RECOMMENDATION_REQUEST: 4,
    PERMIT_REQUEST: 5
};

const DOCUMENT_REQUEST_TYPES_TEXT = {
  [DOCUMENT_REQUEST_TYPES.ATTESTATION_REQUEST]: 'documentRequests.dr_attestation',
  [DOCUMENT_REQUEST_TYPES.STAMPED_FIELD_REPORT]: 'documentRequests.dr_stampedfieldreport',
  [DOCUMENT_REQUEST_TYPES.BUILDING_DEPART_INSPECTION_FORM]: 'documentRequests.dr_buildinginspectionform',
  [DOCUMENT_REQUEST_TYPES.T_PRO]: 'documentRequests.dr_tpro',
  [DOCUMENT_REQUEST_TYPES.RECOMMENDATION_REQUEST]: 'documentRequests.dr_recommendation',
  [DOCUMENT_REQUEST_TYPES.PERMIT_REQUEST]: 'documentRequests.dr_permit'
};

const ROLES = {
    ADMIN: 1,
    DEALER: 2,
    INSTALLER: 3,
    ADVANCED_INSTALLER: 4,
    TECHNICIAN: 6,
    ENGINEER: 7,
    LEADER: 8,
    ENGINEER_ADMIN: 9,
    APPRENTICE: 10,
    ENGINEERING_DEPARTMENT_ADMIN: 11,
    BETA_TESTER: 12
}

const PLATE_FORMS = {
    SQUARE: 0,
    U: 1,
    L: 2,
    BOX: 3,
    UNDERPINNING: 4
}

const AVAILABLE_COUNTRIES = {
    BELGIUM: 22,
    CANADA: 39,
    FRANCE: 75,
    GERMANY: 82,
    LUXEMBOURG: 127,
    POLAND: 176,
    SPAIN: 207,
    SWITZERLAND: 214,
    UNITED_KINGDOM: 232,
    UNITED_STATES: 233
}

const CALCULATION_FORMULA_TYPE = {
    CANADA: 0,
    USA: 1,
    FRANCE: 2
}

const SITE_REPORT_STATUS = {
    DRAFT: 0,
    VALIDATION: 1,
    VALIDATED: 2,
    CANCELED: 3,
    DELETED: 4
}

const SITE_REPORT_STATUS_TEXT = {
  [SITE_REPORT_STATUS.DRAFT]: 'status.draft',
  [SITE_REPORT_STATUS.VALIDATION]: 'status.in_validation',
  [SITE_REPORT_STATUS.VALIDATED]: 'status.validated',
  [SITE_REPORT_STATUS.CANCELED]: 'status.canceled',
  [SITE_REPORT_STATUS.DELETED]: 'status.deleted'
}

const STATUS_COLOR = {
    "black": {"inverted":'#ffffff', "text":'#000000'},
    "blue": {"inverted":'#ffffff', "text":'#2196F3'},
    "blueMagenta": {"inverted":'#000000', "text":'#9999FF'},
    "green": {"inverted":'#ffffff', "text":'#009f4d'},
    "redOrange": {"inverted":'#000000', "text":'#F44336'},
    "violet": {"inverted":'#ffffff', "text":'#993399'},
    "grey": {"inverted": '#000000', "text": '#CCCCCC'}
}

const SITE_REPORT_STATUS_COLOR = {
    [SITE_REPORT_STATUS.DRAFT]: STATUS_COLOR.black,
    [SITE_REPORT_STATUS.VALIDATION]: STATUS_COLOR.blue,
    [SITE_REPORT_STATUS.VALIDATED]: STATUS_COLOR.green,
    [SITE_REPORT_STATUS.CANCELED]: STATUS_COLOR.redOrange,
    [SITE_REPORT_STATUS.DELETED]: STATUS_COLOR.redOrange
  }

const PROJECT_STATUS = {
    POTENTIAL: 3,
    IN_PROGRESS: 0,
    DONE: 4,
    CLOSED: 1,
    CANCELED: 2,
    DELETED: 5
}

const PROJECT_STATUS_TEXT = {
    [PROJECT_STATUS.POTENTIAL]: 'status.potential',
    [PROJECT_STATUS.IN_PROGRESS]: 'status.in_progress',
    [PROJECT_STATUS.DONE]: 'status.done',
    [PROJECT_STATUS.CLOSED]: 'status.closed',
    [PROJECT_STATUS.CANCELED]: 'status.canceled',
    [PROJECT_STATUS.DELETED]: 'status.deleted'
}
  
const PROJECT_STATUS_COLOR = {
    [PROJECT_STATUS.IN_PROGRESS]: STATUS_COLOR.blue,
    [PROJECT_STATUS.CLOSED]: STATUS_COLOR.green,
    [PROJECT_STATUS.CANCELED]: STATUS_COLOR.redOrange,
    [PROJECT_STATUS.POTENTIAL]: STATUS_COLOR.black,
    [PROJECT_STATUS.DONE]: STATUS_COLOR.violet,
    [PROJECT_STATUS.DELETED]: STATUS_COLOR.redOrange
}

const DOCUMENT_REQUEST_STATUS = {
    DRAFT: 0, // Pas encore envoyée dans ClickUp, ou bien À VENIR-ATTENTE/QUESTION
    SENT: 1, // À VENIR, À FAIRE
    IN_PROGRESS: 2, // EN COURS, CORRECTION À FAIRE ADJOINT, À VÉRIFIER PAR ING, À VENIR-ATTENTE/QUESTION, SIGNÉ ET PRÊT POUR ENVOI
    COMPLETED: 3, // Terminé, non fermé
    CLOSED: 4, // Terminé, fermé
    CANCELED: 5 // Pas encore envoyée dans ClickUp, ou bien À VENIR-ATTENTE/QUESTION, Annulé
}

const DOCUMENT_REQUEST_STATUS_TEXT = {
    [DOCUMENT_REQUEST_STATUS.DRAFT]: 'status.draft',
    [DOCUMENT_REQUEST_STATUS.SENT]: 'status.sent',
    [DOCUMENT_REQUEST_STATUS.IN_PROGRESS]: 'status.in_progress',
    [DOCUMENT_REQUEST_STATUS.COMPLETED]: 'status.completed',
    [DOCUMENT_REQUEST_STATUS.CLOSED]: 'status.closed',
    [DOCUMENT_REQUEST_STATUS.CANCELED]: 'status.canceled'
}

const DOCUMENT_REQUEST_STATUS_COLOR = {
    [DOCUMENT_REQUEST_STATUS.DRAFT]: STATUS_COLOR.black,
    [DOCUMENT_REQUEST_STATUS.SENT]: STATUS_COLOR.grey,
    [DOCUMENT_REQUEST_STATUS.IN_PROGRESS]: STATUS_COLOR.blueMagenta,
    [DOCUMENT_REQUEST_STATUS.COMPLETED]: STATUS_COLOR.violet,
    [DOCUMENT_REQUEST_STATUS.CLOSED]: STATUS_COLOR.green,
    [DOCUMENT_REQUEST_STATUS.CANCELED]: STATUS_COLOR.redOrange
  }

const FUNCTIONS = {
    ACCESS_TO_ALL_DEALERS: 1,
    ACCESS_TO_ASSIGNED_DEALERS: 2,
    TRANSFORM_TEMP_CUSTOMER: 3,
    MANAGE_SETTINGS: 4,
    MANAGE_SECURITY: 5,
    ACCESS_TO_CLICKUP: 7,
    SITE_REPORT_VALIDATION: 9,
    SITE_REPORT_DELETE_VALIDATED: 10,
    DOCUMENT_REQUEST_VIEW_LOGS: 11,
    SITE_REPORT_VIEW_LOGS: 12,
    DOCUMENT_REQUEST_CANCEL_REQUEST: 13,
    MODIFICATION_OF_A_SAVED_CLIENT: 14,
    MANAGE_USER_ROLES: 15,
    SITE_REPORT_ALLOW_EDITION: 16,
    DOCUMENT_REQUEST_ALLOW_EDITION: 17,
    MANAGE_INSTALLERS_AND_GROUP: 18,
    SITE_REPORT_FROM_ENGINEERING_DEPARTMENTS_READONLY: 19,
    SITE_REPORT_SELF_DELETE_INSTALLER: 20,
    MANAGE_MACHINE_CALIBRATIONS: 21,
    MOVE_REPORTS_TO_DRAFT_INDEFINITELY: 22,
    ADD_INSTALLER: 23,
    SITE_REPORT_VIEW_CAPACITIES: 24,
    PROJECTS_GOOGLE_MAP: 26,
    MANAGE_VIDEOS: 27,
    BETA_TESTER: 28,
    MANAGE_DOCUMENT_REQUESTS: 29,
    UPLOAD_DOCUMENT_REQUESTS: 30,
    MANAGE_FIELD_REPORTS: 31,
    MANAGE_CUSTOMERS:32,
    MANAGE_NOTIFICATIONS: 35,
    SITE_REPORT_HARD_DELETE: 36,
    MANAGE_NEWS: 37
}

const INSTALLER_STATUS = {
    CERTIFIED: 'certified',
    APPRENTICE: 'apprentice',
}

const CUSTOMER_CATEGORY = {
    HOME_OWNER: 1,
    RESIDENTIAL_COMPANY: 2,
    COMMERCIAL_COMPANY: 3
}

const REPORT_FILE_TYPE = {
    FIELD_PHOTOS: 1,
    PILE_LOCATION_PLAN: 2,
    OTHER_DOCUMENTS: 3,
    PERMIT_PLAN: 4
}

const BEDROCK_ENUM = {
    SPINNING: 0,
    BEDROCK: 1,
    DEPRECATED_FALSE_REFUSAL: 999
}

const VIDEO_CATEGORY = {
    NEW: 0,
    ALL: 1,
    SPECIFIC: 2
  }

const UNIT_MEASURES = {
    METRIC: 0,
    IMPERIAL: 1
}

const SITE_REPORT_DISPLAY_ZONES = {
    ANCHORED_BEDROCK: 'ANCHORED_BEDROCK',
    INFO_WELDING: 'INFO_WELDING', 
    INFO_SELF_TAPPING_SCREW: 'INFO_SELF_TAPPING_SCREW', 
    INFO_STICKERS: 'INFO_STICKERS', 
    INFO_GREEN_SLEEVE: 'INFO_GREEN_SLEEVE', 
    INFO_PILE_ANGLE_CONFORM: 'INFO_PILE_ANGLE_CONFORM', 
    INFO_PILE_POSITION_CONFORM: 'INFO_PILE_POSITION_CONFORM',
    COLLABORATORS: 'COLLABORATORS',
    PLATE_TYPE: 'PLATE_TYPE',
    SOIL_TYPE: 'SOIL_TYPE',
    SAFETY_FACTOR: 'SAFETY_FACTOR',
    PROJECT_DIMENSIONS: 'PROJECT_DIMENSIONS',
    PROJECT_HEIGHT: 'PROJECT_HEIGHT',
    PROJECT_FREE_STRUCTURE: 'PROJECT_FREE_STRUCTURE',
    CONTACTS: 'CONTACTS',
    MACHINES: 'MACHINES',
    PROJECT_CONCRETE_SLAB: 'PROJECT_CONCRETE_SLAB',
    PROJECT_BRICK: 'PROJECT_BRICK',
    PROJECT_SPA: 'PROJECT_SPA',
    INSTALLERS: 'INSTALLERS',
    WELDERS: 'WELDERS',
    INSTALLATION_DATES: 'INSTALLATION_DATES',
    REQUESTS: 'REQUESTS',
    FILES_SITE_PHOTOS: 'FILES_SITE_PHOTOS',
    FILES_PILES_LOCATION: 'FILES_PILES_LOCATION',
    FILES_PERMIT_PLANS: 'FILES_PERMIT_PLANS',
    PILES: 'PILES',
    TORQUE_READINGS: 'TORQUE_READINGS',
    SOIL_TYPE_PDF: 'SOIL_TYPE_PDF',
    INVOICE_LENGTH: 'INVOICE_LENGTH',
    EXTENSION: 'EXTENSION',
    CUSTOMER_CATEGORY: 'CUSTOMER_CATEGORY',
    PILE_NOT_INSTALLED: 'PILE_NOT_INSTALLED',
    PILE_FINISH: 'PILE_FINISH',
    PILE_NAME: 'PILE_NAME',
    CUSTOMER_PHONE: 'CUSTOMER_PHONE',
    CUSTOMER_EMAIL: 'CUSTOMER_EMAIL',
    HAMMER: 'HAMMER',
    PROJECT_REFERENCE_NUMBER: 'PROJECT_REFERENCE_NUMBER',
    TORQUE: 'TORQUE',
    COMPRESSION: 'COMPRESSION',
    TENSION: 'TENSION',
    PDF_TORQUE: 'PDF_TORQUE',
    PDF_COMPRESSION: 'PDF_COMPRESSION',
    PDF_TENSION: 'PDF_TENSION',
    COMMENTS: 'COMMENTS',
    COMMENTS_PDF: 'COMMENTS_PDF',
    PILE_PLATE_DIMENSION: 'PILE_PLATE_DIMENSION',
    PILE_CONCRETE_BLOCK: 'PILE_CONCRETE_BLOCK',
    PILE_HEIGHT_ABV_GROUND: 'PILE_HEIGHT_ABV_GROUND',
    PILE_IMPACTS: 'PILE_IMPACTS',
    PILE_LENGTH: 'PILE_LENGTH',
    PROJECTS: 'PROJECTS',
    PROJECT_TYPES: 'PROJECT_TYPES',
    PERMIT: 'PERMIT'
  }
  
  const SITE_REPORT_DISPLAY_DISPLAYS = {
    VISIBLE_MANDATORY: 'VISIBLE_MANDATORY',
    VISIBLE_OPTIONAL: 'VISIBLE_OPTIONAL',
    HIDDEN: 'HIDDEN',
    FREE_STANDING_STRUCTURE: 'FREE_STANDING_STRUCTURE',
    MANDATORY_WO_REFERENCENB: 'MANDATORY_WO_REFERENCENB',
    MANDATORY_WO_RECOMMENDATION: 'MANDATORY_WO_RECOMMENDATION',
    MANDATORY_WHEN_CHECKED: 'MANDATORY_WHEN_CHECKED'
  }

  const PILE_NOT_INSTALLED_REASON = {
    BEDROCK: 'BEDROCK',
    UNDERGROUND_INSTALLATIONS: 'UNDERGROUND_INSTALLATIONS',
    IMPOSSIBLE_ACCESS: 'IMPOSSIBLE_ACCESS',
    LOCATION_NOT_IDENTIFIED: 'LOCATION_NOT_IDENTIFIED',
    CAPACITY_NOT_ATTAINED: 'CAPACITY_NOT_ATTAINED',
    OTHER: 'OTHER',
    CUSTOMER_REQUEST: 'CUSTOMER_REQUEST'
  }

  const INSTALLER_REPORT_VIEW_OPTIONS = {
    ASSIGNED: 'ASSIGNED',
    NOT_ASSIGNED: 'NOT_ASSIGNED',
    ALL: 'ALL'
  }

  const PROJECT_CANCEL_REASON = {
    NONE: 'NONE',
    SOIL: 'SOIL',
    IRRELEVANT: 'IRRELEVANT',
    BUDGET: 'BUDGET',
    ACCESS: 'ACCESS',
    CURIOUS: 'CURIOUS',
    COMPETITION: 'COMPETITION',
    ERROR: 'ERROR'
  };

  const SOIL_TYPES = [
    { soilTypeEnum: 0, text: 'typesoil.clay'},
    { soilTypeEnum: 1, text: 'typesoil.sand'},
    { soilTypeEnum: 2, text: 'typesoil.silt'},
    { soilTypeEnum: 3, text: 'typesoil.rock'},
    { soilTypeEnum: 4, text: 'typesoil.gravel'}
  ]

  let SOIL_TYPES_INCL_NOTSET = JSON.parse(JSON.stringify(SOIL_TYPES));
  SOIL_TYPES_INCL_NOTSET.push({soilTypeEnum: -1, text: 'typesoil.notset'});
export { 
    REQUEST_TYPES, 
    REQUEST_TYPES_TEXT,
    DOCUMENT_REQUEST_TYPES,
    DOCUMENT_REQUEST_TYPES_TEXT,
    ROLES,
    PLATE_FORMS,
    AVAILABLE_COUNTRIES,
    CALCULATION_FORMULA_TYPE,
    SITE_REPORT_STATUS,
    SITE_REPORT_STATUS_TEXT,
    SITE_REPORT_STATUS_COLOR,
    PROJECT_STATUS,
    PROJECT_STATUS_TEXT,
    PROJECT_STATUS_COLOR,
    DOCUMENT_REQUEST_STATUS,
    DOCUMENT_REQUEST_STATUS_TEXT,
    DOCUMENT_REQUEST_STATUS_COLOR,
    STATUS_COLOR,
    FUNCTIONS,
    INSTALLER_STATUS,
    CUSTOMER_CATEGORY,
    REPORT_FILE_TYPE,
    BEDROCK_ENUM,
    VIDEO_CATEGORY,
    UNIT_MEASURES,
    SITE_REPORT_DISPLAY_ZONES,
    SITE_REPORT_DISPLAY_DISPLAYS,
    PILE_NOT_INSTALLED_REASON,
    INSTALLER_REPORT_VIEW_OPTIONS,
    PROJECT_CANCEL_REASON,
    SOIL_TYPES,
    SOIL_TYPES_INCL_NOTSET
}