<template>
  <v-navigation-drawer
    id="nav-drawer"
    v-model="navState"
    :mini-variant="!isMobile"
    absolute
    :expand-on-hover="!isMobile"
  >
    <v-list nav>
      <NavigationItem
        :url="'/'"
        :active="$route.path.includes('/home')"
        :title="$t('general.home')"
      >
        <HomeIcon :active="$route.name == 'home'" :width="iconWidth" :height="iconHeight"/>
      </NavigationItem>

      <NavigationItem
        v-if="hasAccessToProjects"
        v-cloak 

        :url="'/projects'"
        :active="$route.path.startsWith('/project')"
        :title="$t('projects.projects')"
      >
        <ProjectIcon :active="$route.path.startsWith('/project')" :width="iconWidth" :height="iconHeight"/>
      </NavigationItem>

      <NavigationItem
        v-if="hasAccessToDocumentRequests"
        v-cloak

        :url="'/documentRequests'"
        :active="$route.path.startsWith('/documentRequest')"
        :title="$t('documentRequests.documentRequests')"
      >
        <DocumentRequestIcon :active="$route.path.startsWith('/documentRequest')" :width="iconWidth" :height="iconHeight"/>
      </NavigationItem>

      <NavigationItem
        v-if="hasAccessToFieldReports"
        v-cloak

        :url="'/reports'"
        :active="$route.path.startsWith('/report')"
        :title="$t('documentRequests.dr_fieldreports')"
      >
        <FieldReportIcon :active="$route.path.startsWith('/report')" :width="iconWidth" :height="iconHeight"/>
      </NavigationItem>

      <NavigationItem
        v-if="canManageDealers"
        v-cloak
        
        :url="'/users'"
        :active="$route.path.includes('/user')"
        :title="$t('home.user')"
      >
        <UsersIcon :active="$route.path.includes('/user')" :width="iconWidth" :height="iconHeight"/>
      </NavigationItem>

      <NavigationItem
        v-if="canManageDealers"
        v-cloak
        
        :url="'/dealers'"
        :active="$route.path.includes('/dealer')"
        :title="$t('home.dealer')"
      >
        <DealersIcon :active="$route.path.includes('/dealer')" :width="iconWidth" :height="iconHeight"/>
      </NavigationItem>

      <NavigationItem
        v-if="hasAccessToCustomers"
        v-cloak
        
        :url="'/customers'"
        :active="$route.path.includes('/customer')"
        :title="$t('home.customer')"
      >
        <CustomersIcon :active="$route.path.includes('/customer')" :width="iconWidth" :height="iconHeight"/>
      </NavigationItem>

      <NavigationItem
        v-cloak
        v-if="hasAccessToMap"
        
        :url="'/map'"
        :active="$route.path.includes('/map')"
        :title="$t('home.map')"
      >
        <MapIcon :active="$route.path.includes('/map')" :width="iconWidth" :height="iconHeight"/>
      </NavigationItem>

      <!-- <NavigationItem
        v-cloak
        v-if="hasAccessToBetaTestSection"
        
        :url="'/marketing'"
        :active="$route.path.includes('/marketing')"
        :title="$t('home.marketing')"
      >
        <MarketingIcon :active="$route.path.includes('/marketing')" :width="iconWidth" :height="iconHeight"/>
      </NavigationItem> -->
      
      <NavigationItem
        v-cloak
        v-if="canManageMachineCalibrations"
        
        :url="'/engineeringDepartments'"
        :active="$route.path.includes('/engineeringDepartments')"
        :title="$t('general.engineering_department')"
      >
        <EngineeringDepartmentsIcon :active="$route.path.includes('/engineeringDepartments')" :width="iconWidth" :height="iconHeight"/>
      </NavigationItem>

      <NavigationItem
        v-cloak
        
        :url="'/videos'"
        :active="$route.path.startsWith('/videos')"
        :title="$t('general.videos')"
      >
        <VideoIcon class="video-icon" :active="$route.path.startsWith('/videos')" :width="iconWidth" :height="iconHeight"/>
      </NavigationItem>

      <NavigationItem
        v-cloak
        
        :url="'/news'"
        :active="$route.path.startsWith('/news')"
        :title="$t('general.news')"
      >
        <NewsIcon class="news-icon" :active="$route.path.startsWith('/news')" :width="iconWidth" :height="iconHeight"/>
      </NavigationItem>

    <!--
      </NavigationItem>
      -->
      <NavigationItem
        v-if="canManageParameters"
        v-cloak
        
        :url="'/settings'"
        :active="$route.path.includes('/setting')"
        :title="$t('general.settings')"
      >
        <SettingsIcon :active="$route.path.includes('/setting')" :width="iconWidth" :height="iconHeight"/>
      </NavigationItem>
    
      <!--
      <v-list-item link>
        <v-list-item-icon>
          <img
            style="fill:rgba(0, 0, 0, 0.54)!important;"
            src="/assets/icon/confirmation.svg"
            height="25"
          />
        </v-list-item-icon>
        <v-list-item-title>Validations</v-list-item-title>
      </v-list-item>

      <v-list-item link>
        <v-list-item-icon>
          <img
            style="fill:rgba(0, 0, 0, 0.54)!important;"
            src="/assets/icon/information.svg"
            height="25"
          />
        </v-list-item-icon>

        <v-list-item-title>Informations</v-list-item-title>
      </v-list-item>

      <v-list-item link>
        <v-list-item-icon>
          <img
            style="fill:rgba(0, 0, 0, 0.54)!important;"
            src="/assets/icon/paperclip.svg"
            height="25"
          />
        </v-list-item-icon>
        <v-list-item-title>Documents</v-list-item-title>
      </v-list-item> -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

import FieldReportIcon from '@/components/Icons/FieldReportIcon.vue'
//import EngineeringRequestIcon from '../components/Icons/EngineeringRequestIcon.vue'
import UsersIcon from '@/components/Icons/UsersIcon'
import DealersIcon from '@/components/Icons/DealersIcon'
import CustomersIcon from '@/components/Icons/CustomersIcon'
import MapIcon from '@/components/Icons/MapIcon'
import SettingsIcon from '@/components/Icons/SettingsIcon'
import HomeIcon from '@/components/Icons/HomeIcon'
import EngineeringDepartmentsIcon from '@/components/Icons/EngineeringDepartmentsIcon'
//import MarketingIcon from '@/components/Icons/MarketingIcon'
import VideoIcon from '@/components/Icons/VideoIcon'
import NewsIcon from '@/components/Icons/NewsIcon'
import ProjectIcon from '@/components/Icons/ProjectIcon'
import DocumentRequestIcon from '@/components/Icons/DocumentRequestIcon'

import NavigationItem from '@/components/menus/NavigationItem';

export default {
  computed: {
    ...mapState({
      drawer: (state) => state.drawer,
      userFunctions: (state) => state.userFunctions,
      isMobile: state => state.isMobile,
    }),
    ...mapGetters({
      canManageSecurity: 'canManageSecurity',
      canManageDealers: 'canManageDealers',
      canManageParameters: 'canManageParameters',
      canManageMachineCalibrations: 'canManageMachineCalibrations',
      hasAccessToMap: 'hasAccessToMap',
      hasAccessToCustomers: 'hasAccessToCustomers',
      hasAccessToDocumentRequests: 'hasAccessToDocumentRequests',
      hasAccessToFieldReports: 'hasAccessToFieldReports',
      hasAccessToProjects: 'hasAccessToProjects'
    }),
    navState: {
      get: function() {
        return this.drawer;
      },
      set: function(newValue) {
        this.SET_NAV(newValue);
      },
    },
    iconWidth: () => '25px',
    iconHeight: () => '25px',
  },
  components: {
    FieldReportIcon,
    //EngineeringRequestIcon,
    UsersIcon,
    DealersIcon,
    CustomersIcon,
    MapIcon,
    SettingsIcon,
    HomeIcon,
    NavigationItem,
    EngineeringDepartmentsIcon,
    //MarketingIcon,
    VideoIcon,
    NewsIcon,
    ProjectIcon,
    DocumentRequestIcon
  },
  methods: {
    ...mapActions({
    }),
    ...mapMutations([
      'SET_NAV'
    ]),
  },
  created: function() {},
};
</script>

<style>
.v-list-item--active{
  color:#009f4d!important;
}
.video-icon, .news-icon {
  stroke: rgba(0, 0, 0, 0.87) !important;
  fill: white !important;
}
.video-icon.v-list-item--active, .news-icon.v-list-item--active {
  stroke: #009f4d !important;
}
</style>