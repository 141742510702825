import Vue from "vue";
import Vuex from "vuex";
import { auth } from "./auth.module";

import mutations from './mutations';
import state from './state';
import actions from "./actions";
import { FUNCTIONS } from "../enums";

Vue.use(Vuex);


const store = new Vuex.Store({
  state: state,
  mutations: mutations,
  actions: actions,
  modules: {
    auth,
  },
  getters: {
    canManageSecurity: state => {
      return state.userFunctions && state.userFunctions.find(x => x.id === FUNCTIONS.MANAGE_SECURITY);
    },
    canManageNotifications: state => {
      return state.userFunctions && state.userFunctions.find(x => x.id === FUNCTIONS.MANAGE_NOTIFICATIONS);
    },
    canManageDealers: state => {
      return state.userFunctions && (state.userFunctions.find(x => x.id === FUNCTIONS.ACCESS_TO_ALL_DEALERS || state.userFunctions.find(x => x.id === FUNCTIONS.ACCESS_TO_ASSIGNED_DEALERS)));
    },
    hasAccessToMap: state => {
      return state.userFunctions && state.userFunctions.find(x => x.id === FUNCTIONS.PROJECTS_GOOGLE_MAP);
    },
    hasAccessToCustomers: state => {
      return state.userFunctions && state.userFunctions.find(x => x.id === FUNCTIONS.MANAGE_CUSTOMERS);
    },
    hasAccessToBetaTestSection: state => {
      return state.userFunctions && state.userFunctions.find(x => x.id === FUNCTIONS.BETA_TESTER);
    },
    hasAccessToDocumentRequests: state => {
      return state.userFunctions && state.userFunctions.find(x => x.id === FUNCTIONS.MANAGE_DOCUMENT_REQUESTS);
    },
    hasAccessToFieldReports: state => {
      return state.userFunctions && state.userFunctions.find(x => x.id === FUNCTIONS.MANAGE_FIELD_REPORTS);
    },    
    hasAccessToProjects: state => {
      return state.userFunctions && (state.userFunctions.find(x => x.id === FUNCTIONS.ACCESS_TO_ALL_DEALERS) || state.userFunctions.find(x => x.id === FUNCTIONS.ACCESS_TO_ASSIGNED_DEALERS));
    },
    canManageVideos: state => {
      return state.userFunctions && state.userFunctions.find(x => x.id === FUNCTIONS.MANAGE_VIDEOS);
    },
    canManageNews: state => {
      return state.userFunctions && state.userFunctions.find(x => x.id === FUNCTIONS.MANAGE_NEWS);
    },
    canModifyInstallers: state => {
      return state.userFunctions && state.userFunctions.find(x => x.id === FUNCTIONS.MANAGE_INSTALLERS_AND_GROUP);
    },
    canManageParameters: state => {
      return state.userFunctions && state.userFunctions.find(x => x.id === FUNCTIONS.MANAGE_SETTINGS);
    },
    canManageMachineCalibrations: state => {
      return state.userFunctions && state.userFunctions.find(uf => uf.id == FUNCTIONS.MANAGE_MACHINE_CALIBRATIONS);
    },
    canManageCustomers: state => {
      return state.userFunctions && (state.userFunctions.find(uf => uf.id == FUNCTIONS.MODIFICATION_OF_A_SAVED_CLIENT))
    },
    canValidateReports: state => {
      return state.userFunctions && (state.userFunctions.find(uf => uf.id == FUNCTIONS.SITE_REPORT_VALIDATION))
    }
  }
});

export default store;